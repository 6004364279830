/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-09-Th 07:12:08
 */
/* global __GTAG_ID__ */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import VueGtag from 'vue-gtag';

import {rejectFailedStatus} from '@yqg/http';

import 'ssr-common/util/report';

import {initSensors} from '@shared/client/util/sensors';
import {initSentry} from '@shared/client/util/sentry';
import {getUserId} from '@shared/client/util/user-token';

import {isServer} from 'ssr-common/constant/config';
import {setSentryUser} from 'ssr-common/util/sentry-user';
import {isYqdCredMex, minVersion} from 'ssr-common/util/webview/ua';
import {createClientEntry} from 'ssr-common/vue/ssr/entry-client';
import http from 'ssr-common/vue/vue-http';

import {getKey} from 'credmex-new/common/util/gen-key';

import EventUtil from 'easycash/common/util/event';

import {rootTitle, BRAND} from './common/constant/config';
import {createApp} from './main';

http.interceptors.response.use(rejectFailedStatus());

const {router} = createClientEntry({createApp, rootTitle});

Vue.use(VueGtag, {
    config: {id: __GTAG_ID__}
}, router);

// sensors start
// 墨西哥只上报h5，不要官网
const isInApp = isYqdCredMex(navigator.userAgent);
if (minVersion(30602) || (!isInApp && (location.pathname.startsWith('/webview/') || location.pathname.startsWith('/native/')))) {
    const platformName = `credmex_${isInApp ? 'app' : 'outside'}`;
    Vue.prototype.$sensors = initSensors({
        platformName,
        country: 'mex',
        businessName: 'MEX_YQD',
        userId: getUserId(),
        useAppJsBridge: isInApp
    });
}
// sensors end

router.afterEach(to => {
    const {query, meta = {}, name} = to;
    const {business = {}} = meta;
    const {
        enterEvent,
        uploadQuery,
        buriedPoint,
        withUserKey,
    } = business;

    if (enterEvent && !isServer() && !buriedPoint) {
        const {elementContent = {}} = enterEvent;
        const event = {
            ...enterEvent,
            elementContent: uploadQuery ? JSON.stringify({
                ...query, ...elementContent
            }) : JSON.stringify({...elementContent})
        };
        EventUtil.uploadEvent(event, to, BRAND);
    }

    if (buriedPoint && !isServer()) {
        const finalQuery = withUserKey ? {...query, userKey: getKey()} : query;

        const event = {
            tag: 'PageEnter',
            businessEvent: `Credmex_${name}_PageEnter`,
            elementType: 'Button',
            eventType: 'P',
            elementContent: uploadQuery ? finalQuery : {}
        };
        EventUtil.uploadEvent(event, to, BRAND);
    }
});

initSentry({
    router,
    dsn: 'https://c1208b4f791b967eb4b230b5b3ffc827@sentry.fintopia.tech/70'
});

setSentryUser();

if (module.hot) {
    module.hot.accept();
}
